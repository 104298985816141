.save-card-edit {
    cursor: pointer;
}

.delete-card {
    cursor: pointer;
}

.input-card-title {
    width: 94%;
    line-height: 14px;
    border-radius: 3px;
    box-shadow: inset 0 0 0 2px #026aa7;
    box-sizing: border-box;
    padding: 8px 12px;
    border: hidden;
    outline: none;
    margin: 0 8px 8px 8px;
}

.edit-buttons {
    display: flex;
    justify-content: space-evenly;
}

.card-title {
    word-break: break-word;
}

.dragStart {
    color: rgba(0, 0, 0, 0);

    &:hover {
        background-color: white;
    }
}