body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { 
   height: 100%;   
}

body { 
  height: 100%;   
}

#root { 
  height: 100%;   
}

.home {
  background: rgb(255, 255, 255);
  min-width: 100%;
  min-height: 100%;
}

.simplebar-scrollbar::before {
  background-color: rgb(211, 209, 209);  
}

.simplebar-track.simplebar-horizontal {
  z-index: 1;
  position: fixed;
  bottom: 2px;
  pointer-events: all; 
  overflow: hidden;
  background-color: rgba(40, 40, 40, 0.463); 
  border-radius: 8px;
  height: 17px; 
  left: 50px;
  right: 50px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  left: 0;
  top: 0;
  height: 17px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}